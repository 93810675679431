






































import Vue from "vue";
import axios from "axios";

export default Vue.extend({
  props: {
    msg: String,
  },
  data: function () {
    return { email: "", message: "", sending: false };
  },
  methods: {
    send: async function () {
      this.sending = true;

      try {
        const response = await axios.post("/api/Contact", {
          email: this.email,
          message: this.message,
        });

        this.email = "";
        this.message = "";

        this.$buefy.toast.open({
          message: "Bedankt, we nemen snel contact met je op.",
          type: "is-success",
        });
      } catch (err) {
        this.$buefy.toast.open({
          message: "Bericht niet verzonden, er ging wat mis.",
          type: "is-danger",
        });
      } finally {
        this.sending = false;
      }
    },
  },
  computed: {
    valid: function (): boolean {
      if (this.email && this.message) {
        return true;
      }
      return false;
    },
  },
});
