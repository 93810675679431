















import Vue from "vue";
import Quest from "./components/Quest.vue";
import Code from "./components/Code.vue";
import Nav from "./components/Nav.vue";
import Banner from "./components/Banner.vue";
import Footer from "./components/Footer.vue";
import Contact from "./components/Contact.vue";
import CodeResponse from "./components/CodeReponse";

export default Vue.extend({
  name: "App",
  components: {
    Quest,
    Nav,
    Banner,
    Code,
    Contact,
    Footer,
  },
  data(): { quest: CodeResponse | undefined } {
    return { quest: undefined };
  },
  methods: {
    setQuest: function (quest: CodeResponse) {
      window.scrollTo(0, 0);
      this.quest = quest;
      window.location.hash = "start";
    },
    showHelp: function () {
      this.$buefy.dialog.confirm({
        title: "Help",
        type: "is-success",
        cancelText: 'Nee het lukt wel',
        confirmText: 'Contact opnemen',
        message: "Loop je vast? Neem contact op, we helpen je snel verder. ",
        onConfirm: () => window.open(`https://wa.me/${this.quest?.number}?text=HELP`, '_blank')?.focus(),
      });
    },
  },
  computed: {
    showQuest: function (): boolean {
      return this.quest !== undefined;
    },
  },
});
