


































import Vue from "vue";
import CodeResponse from "./CodeReponse";
import axios from "axios";

async function getQuest(code: string): Promise<CodeResponse> {
  //return { name: "Henk", image: "wiel.jpg", number: "316111111" };

  const response = await axios.post<CodeResponse>("/api/GetQuest", {
    code: code,
  });
  return response.data;
}

export default Vue.extend({
  data: function () {
    return {
      code: "",
      loading: false,
      codeInvalid: false,
    };
  },
  mounted: function () {
    const code = window.location.hash;
    if (code && code.startsWith("#code=")) {
      this.code = code.replace("#code=", "");
      const el = document.getElementById("code");
      if (el) {
        //el.scrollIntoView({ behavior: "smooth" });
      }
    }
  },
  methods: {
    startQuest: async function () {
      this.loading = true;
      this.codeInvalid = false;

      try {
        const quest = await getQuest(this.code);
        this.$emit("codeEntered", quest);
      } catch (err) {
        this.codeInvalid = true;
      } finally {
        this.loading = false;
      }
    },
  },
});
