import Vue from "vue";
import App from "./App.vue";
import Buefy from "buefy";
import "./Icons";
import "./style.scss";
import { ApplicationInsights } from '@microsoft/applicationinsights-web'


Vue.config.productionTip = false;

Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
});

new Vue({
  render: (h) => h(App),
}).$mount("#app");

const appInsights = new ApplicationInsights({ config: {
  instrumentationKey: '68996949-1dc0-4df6-bad3-42e2b24d15c8',
  enableAutoRouteTracking: true
} });
appInsights.loadAppInsights();
appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview