













































































































import Vue from "vue";
import CodeResponse from "./CodeReponse";

export default Vue.extend({
  props: {
    quest: { type: Object as () => CodeResponse },
  },
  data: function () {
    return { isHint: false, question: 1 };
  },
  methods: {
    setQuestion: function (questionNumber: number) {
      this.question = questionNumber;
      window.scrollTo(0, 0);
      window.location.hash = "question" + questionNumber;
    },
    getImage: function () {
      return require("@/assets/quest/" + this.quest.image);
    },
  },
});
